import {
  Account,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner } from "@/libs/solanaConnection";
import { anchor_init, get_pda, prepare_ata } from "@/libs/solanaProgram";
import { signAndSendTransaction } from "@/libs/wallet";

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

import _idl from "@/IDL.json";
const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('Dmk17dvCbLZbR5ZLezS3yTqcj9wJq6ki39pYwMGjK6QU');

let connection: Connection;


import axios from 'axios';
import bs58 from "bs58";

const VAULT_ACCOUNT_PREFIX = 'saasrafffle221203';
const VAULT_AUTHORITY_PREFIX = 'saasrafffleauth221203';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

var program;

/**
 * 
 * Create a new raffle
 * 
 */
export async function create_new_raffle(wallet_provider, public_key, mint, price, max_tickets, end_time, decimals, project_id) {
	
	connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	mint = new PublicKey(mint);
	public_key = new PublicKey(public_key);
	
	
	var number_random_for_seeds = Math.floor(Math.random() * 256)
	
	const vault_account_pda = await get_pda(connection, program_id, [Buffer.from(VAULT_ACCOUNT_PREFIX),[number_random_for_seeds], mint.toBuffer(), public_key.toBuffer()]);
	
	const vault_account_authority = await get_pda(connection, program_id, [Buffer.from(VAULT_AUTHORITY_PREFIX),[number_random_for_seeds], mint.toBuffer(), public_key.toBuffer()]);
	
	const escrow = anchor.web3.Keypair.generate();
	
	const token_account = await getTokenAccountForTokenAndOwner(mint.toString(), public_key.toString());
	
	const transaction = await program.transaction.create(
		new anchor.BN(price),
		number_random_for_seeds,
		new anchor.BN(max_tickets),
		new anchor.BN(decimals),
		new anchor.BN(end_time),
		project_id,
		{
			accounts: {
				mint: mint,
				projectOwner: public_key,
				
				vaultAccount: vault_account_pda,
				vaultAuthority: vault_account_authority,
				
				tokenAccount: new PublicKey(token_account),
				escrowAccount: escrow.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccount.createInstruction(escrow),
			],
			// signers: [wallet_provider, escrow],
		}
	);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	// console.log('escrow', escrow.publicKey.toString());
	// console.log('vault_account_pda', vault_account_pda.toString());
	// console.log('token_account', token_account.toString());
	
	return [signature, escrow.publicKey];
}

/**
 * 
 * Create a new raffle (for WL)
 * 
 */
export async function create_new_raffle_wl(wallet_provider, public_key, price, max_tickets, end_time, decimals, project_id) {
	
	connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	public_key = new PublicKey(public_key);
	
	const escrow = anchor.web3.Keypair.generate();
	
	const transaction = await program.transaction.createwl(
		new anchor.BN(price),
		new anchor.BN(max_tickets),
		new anchor.BN(decimals),
		new anchor.BN(end_time),
		project_id,
		{
			accounts: {
				projectOwner: public_key,
				
				escrowAccount: escrow.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccount.createInstruction(escrow),
			],
			// signers: [wallet_provider, escrow],
		}
	);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	// console.log('escrow', escrow.publicKey.toString());
	// console.log('vault_account_pda', vault_account_pda.toString());
	// console.log('token_account', token_account.toString());
	
	return [signature, escrow.publicKey];
}

/**
 * 
 * Claim nft reward
 * 
 */
export async function claim(wallet_provider, public_key, mint, escrow, project_owner) {
	
	connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var escrow_data = await program.account.escrowAccount.fetch(escrow);
	
	mint = new PublicKey(mint);
	public_key = new PublicKey(public_key);
	escrow = new PublicKey(escrow);
	project_owner = new PublicKey(project_owner);
	
	const vault_account_pda = await get_pda(connection, program_id, [Buffer.from(VAULT_ACCOUNT_PREFIX),[escrow_data.numberRandomForSeeds], mint.toBuffer(), project_owner.toBuffer()]);
	
	const vault_account_authority = await get_pda(connection, program_id, [Buffer.from(VAULT_AUTHORITY_PREFIX),[escrow_data.numberRandomForSeeds], mint.toBuffer(), project_owner.toBuffer()]);
	
	const [instruction_ata, associatedDestinationTokenAddr] = await prepare_ata(connection, mint, public_key, public_key);
	
	// console.log('escrow_data', escrow_data);
	console.log('vault_account_pda', vault_account_pda.toString());
	console.log('mint', mint.toString());
	// console.log('vault_account_authority', vault_account_authority.toString());
	
	const instruction = await program.instruction.claim(
		{
			accounts: {
				mint: mint,
				winner: public_key,
				
				vaultAccount: vault_account_pda,
				vaultAuthority: vault_account_authority,
				
				projectOwner: project_owner,
				
				tokenAccountWinner: new PublicKey(associatedDestinationTokenAddr),
				escrowAccount: escrow,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		}
	);
	
	var transaction = new Transaction();
	
	if(instruction_ata !== null)
		transaction.add(instruction_ata);
	
	transaction.add(instruction);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	var signedTransaction = await wallet_provider.signTransaction(transaction);
	
	var signature = await connection.sendRawTransaction(signedTransaction.serialize());
	
	console.log('signature', signature);
	
	return signature;
}

/**
 * 
 * Buy some tickets
 * 
 */
export async function buy(wallet_provider, public_key, tickets, escrow, token_account, token) {
	
	connection = await establishConnection();
	
	
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var escrow_data = await program.account.escrowAccount.fetch(escrow);
	
	// console.log(escrow_data);
	
	
	var buyer_token_account = await getTokenAccountForTokenAndOwner(token, public_key);
	
	if(buyer_token_account === null)
		return null;
	
	escrow = new PublicKey(escrow);
	token_account = new PublicKey(token_account);
	token = new PublicKey(token);
	public_key = new PublicKey(public_key);
	buyer_token_account = new PublicKey(buyer_token_account);
	
	const transaction = await program.transaction.buy(
		tickets,
		{
			accounts: {
				buyer: public_key,
				buyerTokenAccount: buyer_token_account,
				
				tokenAccount: token_account,
				token: token,
				escrowAccount: escrow,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		}
	);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	var signedTransaction = await wallet_provider.signTransaction(transaction);
	
	var signature = await connection.sendRawTransaction(signedTransaction.serialize());
	
	console.log('signature', signature);
	
	return signature;
}

/**
 * 
 * Update winner
 * 
 */
export async function check_winner(escrow, wallet_provider) {
	
	connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var escrow_data = await program.account.escrowAccount.fetch(escrow);
	
	console.log(escrow_data);
	console.log(escrow_data.winnerWalletAddress.toString());
}
