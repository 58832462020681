import { PublicKey } from '@solana/web3.js';

import * as anchor from '@project-serum/anchor';

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

export async function get_pda(connection, program_id, seeds) {

	const [pda, bump] = await PublicKey.findProgramAddress(seeds, program_id);

	return pda;
}


/**
 * 
 * Init Anchor
 * 
 */
export function anchor_init(connection, wallet_provider, program_id, idl) {
	
	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	var program = new anchor.Program(idl, program_id, provider);
	
	return program;
}

/**
 * 
 * Prepare Associated Token Account
 * 
 */
export async function prepare_ata(connection, mint_address, destination, payer_publickey) {
	
	mint_address = new PublicKey(mint_address);
	destination = new PublicKey(destination);
	payer_publickey = new PublicKey(payer_publickey);
	
	// we create the tokenAccount
	var myToken = new Token(
		connection,
		mint_address,
		TOKEN_PROGRAM_ID,
		payer_publickey
	)
	
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		myToken.associatedProgramId,
		myToken.programId,
		mint_address,
		destination,  // to
		true
	)
	
	// check if it exists
	var receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr)
	
	var instruction = null
	
	if (receiverAccount === null) {

		instruction = Token.createAssociatedTokenAccountInstruction(
			myToken.associatedProgramId,
			myToken.programId,
			mint_address,
			associatedDestinationTokenAddr,
			destination, // to
			payer_publickey // signer
		)
	}
	
	return [instruction, associatedDestinationTokenAddr]
}

